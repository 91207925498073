import * as React from "react";
import { Helmet } from "react-helmet";

import { useConfig } from "gatsby-theme-advanced";

import Layout from "../layouts";
import styled from "styled-components";
import { LayoutWidthContainer } from "../components/shared";

const AboutPage = (): JSX.Element => {
  const config = useConfig();

  const Wrapper = styled.main`
    width: 100%;

    display: grid;
    grid-gap: 24px;
    justify-items: center;

    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: 0.5px;
  `;

  return (
    <Layout>
      <div className="about-container">
        <Helmet title={`About | ${config.website.title}`} />
      </div>
      <Wrapper>
        <LayoutWidthContainer>
          Xin chào các bạn đọc,
          <br />
          <br />
          Tôi đã dò dẫm trong bóng tối nhiều năm, cho tới một ngày tôi chợt bừng
          tỉnh và nhận ra rằng: mình đang ở trong bóng tối, bóng tối ở quanh tôi
          và tôi cần tự thắp sáng để soi rõ con đường tôi đi. Kể từ đó, mỗi ngày
          tôi đều làm một việc: <b>thắp sáng ngọn đèn trong tôi</b>, để soi tỏ,
          để nhìn thấy Tôi, nhìn thấy những thứ quanh tôi. Lúc đó, tôi mới nhìn
          thấy được những gì trước nay chưa từng thấy.
          <br />
          <br />
          Hoá ra thế giới quanh tôi như thế này sao? Mỗi lần ngộ ra một điều gì
          đó chính là lúc ngọn đèn tôi thắp lại sáng thêm một chút, vùng sáng
          lại rộng thêm một chút để cho tôi thấy được nhiều thứ hơn. Tôi thực sự
          thì vẫn là tôi thôi, một con người nhỏ bé, một hạt cát trên sa mạc bao
          la rộng lớn, và vẫn tiếp tục thắp sáng thêm cho ngọn đèn trong tôi để
          soi tỏ con đường tôi đi, để không còn lạc lối nữa. Sau sự động viên
          của lão chồng, tôi quyết định lên đây chia sẻ những trải nghiệm của
          mình và hi vọng rằng nó sẽ có ích cho bạn khi bạn thắp ngọn đèn của
          chính mình.
          <br />
          <br />
          Thân mến!
        </LayoutWidthContainer>
      </Wrapper>
    </Layout>
  );
};

export default AboutPage;
